import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { TABLESIDE_SETUP, TABLESIDES, TABLESIDE_TICKETS } = API_ENDPOINTS;

export const TableSideAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting TableSide
    getOrders: builder.query({
      query: () => `/${TABLESIDE_SETUP}/GetOrders`,
      // transformResponse: (response) => response?.data,
      keepUnusedDataFor: 2,
      //   providesTags: (response) => {
      //     return response
      //       ? [
      //           ...response.map(({ id }) => ({ type: TABLESIDES, id })),
      //           TABLESIDES,
      //         ]
      //       : [TABLESIDES];
      //   },
    }),
    createTablesideSetup: builder.mutation({
      query: (data) => ({
        url: `/${TABLESIDE_SETUP}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: TABLESIDES, id: result?.id }],
    }),
    updateTablesideSetup: builder.mutation({
      query: ({id, data}) => ({
        url: `/${TABLESIDE_SETUP}/${id}`,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result) => [{ type: TABLESIDES, id: result?.id }],
    }),
    getPendingMeals: builder.query({
      query: ({facilityId}) =>
        `/${TABLESIDES}/PendingMeals?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 0,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: TABLESIDES,
                id,
              })),
              TABLESIDES,
            ]
          : [TABLESIDES];
      },
    }),
    createMeal: builder.mutation({
      query: (data) => ({
        url: `/${TABLESIDES}/CreateMeal`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: TABLESIDES, id: result?.id }],
    }),
    deleteMeal: builder.mutation({
      query: ({ id, FacilityId }) => ({
        url: `/${TABLESIDES}/DeleteMeal?id=${id}&FacilityId=${FacilityId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TABLESIDES, id: arg.id },
      ],
    }),
    getTablesideTicketList: builder.query({
      query: ({ params }) =>
        `/${TABLESIDE_TICKETS}/OpenTicketList?facilityId=${
          params.facilityId
        }&mealId=${params.mealId || "0"}&diningAreaId=${
          params.diningAreaId || "0"
        }&startDate=${params.startDate || ""}&endDate=${
          params.endDate || ""
        }&showAll=${params.showAll || false}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: TABLESIDE_TICKETS,
                id,
              })),
              TABLESIDE_TICKETS,
            ]
          : [TABLESIDE_TICKETS];
      },
    }),
    openTablesideTicketReport: builder.mutation({
      query: ({ facilityId, ticketIds }) => ({
        url: `/${TABLESIDE_TICKETS}/OpenTicketReport?facilityId=${facilityId}`,
        method: "POST",
        body: ticketIds,
      }),
      invalidatesTags: [TABLESIDE_TICKETS],
    }),
    tablesideReport: builder.mutation({
      query: (data) => ({
        url: `/${TABLESIDES}/TablesideReports`,
        method: "POST",
        body: data,
      }),
    }),
    reprintOrder: builder.mutation({
      query: (orderId) => ({
        url: `/${TABLESIDES}/ReprintOrder?orderID=${orderId}`,
        method: "POST",
      }),
      // invalidatesTags: (result) => [{ type: TABLESIDES, id: result?.id }],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetOrdersQuery,
  useCreateTablesideSetupMutation,
  useUpdateTablesideSetupMutation,
  useGetPendingMealsQuery,
  useCreateMealMutation,
  useDeleteMealMutation,
  useGetTablesideTicketListQuery,
  useOpenTablesideTicketReportMutation,
  useTablesideReportMutation,
  useReprintOrderMutation,
} = TableSideAPIs;
