import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { debounce, isUndefined } from "lodash";
import {
  useGetSubRecipeListQuery,
  useGetSubRecipeSearchListQuery,
  useAddSubRecipeMutation,
  useUpdateSubRecipeMutation,
} from "../../../../../store/apis/PlateFulSubRecipeApis";
import { useForm } from "react-hook-form";
import {
  // FormControl,
  Card,
  // Box,
  Grid,
  Box,
  Button,
} from "@mui/material";
import BaseAutoComplete from "../../../../shared/baseForm/BaseAutoComplete";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";
import { useCustomDrop } from "components/shared/customeHooks/useCustomDrop";
import SubRecipeDragWrapper from "./SubRecipeDragWrapper";
// import { DMStyles } from "../../../../../styles/Styles";

export default function SubRecipe() {

  const [searchParams] = useSearchParams();
  const currentLocation = useLocation();
  const canEdit = currentLocation.state?.canEdit;
  const recipeId = searchParams.get("recipeId");
  const [subRecipeData, setSubRecipeData] = useState([]);
  const [initialOrder, setInitialOrder] = useState([]);
  const [canSaveOrder, setCanSaveOrder] = useState(false);

  const {
    data: subRecipe,
    isError: hasError,
    isLoading: loading,
  } = useGetSubRecipeListQuery(
    { recipeId: recipeId },
    { skip: !recipeId, refetchOnMountOrArgChange: true }
  );

  const [addSubRecipe, { isError: addError, isLoading: addLoading }] =
    useAddSubRecipeMutation();

  const [updateSubRecipes, 
    {isError: updateError, isLoading: updateLoading, isSuccess: updateSuccess}] = useUpdateSubRecipeMutation();

  useEffect(() => {
    if (!updateError && updateSuccess) {
      setCanSaveOrder(false);
    }

  }, [updateError, updateSuccess]);

  const isError = hasError || addError || updateError;
  const isLoading = loading || addLoading || updateLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = methods;

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [resetSearch, setResetSearch] = useState(false);
  const [Search, setSearchdata] = useState();
  const { data: searchData, isLoadingtest } = useGetSubRecipeSearchListQuery(
    {
      key: debouncedSearch,
    },
    {
      skip: !debouncedSearch,
    }
  );

  //below code is added to filter the sub Recipes Search from Added Sub Recipe Data.
  useEffect(() => {
    if (searchData?.length > 0) {
      if (subRecipe) {
        setSearchdata(
          searchData?.filter(
            (item) => !subRecipe.some((item2) => item2.idSubRecipe === item.id)
          )
        );
      } else {
        setSearchdata(searchData);
      }
    }
  }, [subRecipe, searchData, debouncedSearch]);

  //loading a copy for DnD purposes
  useEffect(() => {
    if (subRecipe) {
      setSubRecipeData(subRecipe.map((sub, idx) => {
        return {
          ...sub,
          index: idx
        }
      }));
      setInitialOrder(subRecipe.map((sub, idx) => {
        return {
          subRecipe: sub.subRecipe,
          sortOrder: idx
        }
      }));
    }
  }, [subRecipe]);

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query);
      }, 400),
    []
  );

  const moveSubRecipe = useCallback(
    (dragIndex, hoverIndex, item) => {
      if (!isUndefined(dragIndex)) {
        const dataCopy = structuredClone(subRecipeData);
        const initialIdx = subRecipeData?.findIndex((sub) => sub?.id === item?.id);
        const dragRow = subRecipeData[initialIdx];

        if (dragRow && initialIdx > -1) {
          dataCopy.splice(initialIdx, 1);
          dataCopy.splice(hoverIndex, 0, dragRow);
        }

        setSubRecipeData(() => dataCopy.map((sub, idx) => {
          return {
            ...sub,
            index: idx
          }
        }));
      }
    },
    [subRecipeData]
  );

  const sortChange = (item, monitor) => {
    
    const rows = document.getElementsByClassName("subRecipeWrapper");
    const lastItem = rows[rows?.length - 1];
    const bottomBox = lastItem?.lastElementChild?.getBoundingClientRect();
    const currentBottomYThreshold = bottomBox?.bottom - (bottomBox?.height / 2);
    
    // EDGE CASE
    // if user did not trigger row shifting by dragging outside of 
    // the grid and coming back in deadzone (filler) at bottom
    // update list and perform a manual check for sort order save
    if (monitor.getClientOffset()?.y > currentBottomYThreshold) {
      const moveFromIdx = item.index;
      item.index = subRecipeData.length - 1;
      moveSubRecipe(moveFromIdx, item.index, item);
      setCanSaveOrder(() => initialOrder[initialOrder?.length - 1]?.subRecipe !== item?.subRecipe);
    } else {
      setCanSaveOrder(() => subRecipeData?.some((sub, idx) => sub.subRecipe !== initialOrder[idx]?.subRecipe));
    }

  }

  const { isOver, drop } = useCustomDrop("grid", sortChange);


  const SubRecipeColumns = [
    {
      field: "subRecipe",
      headerName: "Name",
      sortable: false, //sort order is now controlled by user
      flex: 4,
      renderCell: (params) => {
        return (
          <SubRecipeDragWrapper props={params} index={params?.row?.index} moveSubRecipe={moveSubRecipe} />
        )
      }
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={subRecipe}
            hasDelete={canEdit}
            deleteProps={{
              entityName: params.row?.subRecipe,
              apiPath: "deleteSubRecipe",
              title: "Sub Recipe",
            }}
          />
        );
      },
    },
  ];

  const onSubmit = async (data) => {
    addSubRecipe({ ...data, parentId: parseInt(recipeId) });
    reset({ subRecipeId: "" });
    setResetSearch(!resetSearch);
  };

  const onSaveSortOrder = () => {
    const saveData = subRecipeData.map((sub, idx) => {
      return {
        ...sub,
        sortOrder: ++idx //shift to 1 based indices for DB save
      }
    });

    updateSubRecipes(saveData);
  }

  return (<>
    <Card>
      <Grid container spacing={1} sx={{
        padding: 1
      }}>
        {canEdit ? (
          <>
            <Grid item xs={4}>
              <BaseAutoComplete
                formSelectProps={{
                  props: {
                    name: "subRecipeId",
                    label: "Sub Recipe",
                    required: true,
                  },
                  validationProps: {},
                }}
                isLabelBold={true}
                control={control}
                errors={errors}
                options={Search || []}
                loading={isLoading}
                defaultOptions={[]}
                handleSearch={handleSearch}
                resetSearch={resetSearch}
              />
            </Grid>
            <Grid item xs={2}>
              <Box>
                <BaseSubmitButton
                  sx={{
                    height: 55,
                  }}
                  disabled={!watch("subRecipeId")}
                  text="Add"
                  isSubmitting={isLoadingtest}
                  onClick={handleSubmit(onSubmit)}
                />
              </Box>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} ref={drop}>
          <BaseDataGrid
            autoHeight={false}
            rows={subRecipeData}
            columns={SubRecipeColumns}
            loading={isLoading}
            error={isError}
            disableColumnMenu
            sx={{opacity: isOver ? .6 : 1}}
            height={{ md: "56vh", lg: "58vh", xl: "68vh" }}
          />
        </Grid>
        <Button 
          variant="contained" 
          color="primary"
          sx={{ margin: ".5rem 0 .5rem .5rem" }} 
          disabled={!canSaveOrder} 
          onClick={() => onSaveSortOrder()}
        >
          Save Sort Order
        </Button>
      </Grid>
    </Card>
  </>);
}
