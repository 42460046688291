import { Box, Grid } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import BaseContent from "components/shared/baseContent/BaseContent";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetFacilityCustomChargeByIdQuery, useUpdateFacilityCustomChargeMutation } from "store/apis/FacilityApis";
import { TableSideCustomChargesFields } from "../form.constants";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";

export default function EditCharge() {
  const [searchParams] = useSearchParams();
  const chargeId = searchParams.get("id");
  const navigate = useNavigate();
  const facilityId = useSelector(selectFacilityId);
  const facilityName = useSelector((state) => state.userInfo.facilityName);
  const [hiddenList, setHiddenList] = useState([]);
  const [hasChange, setHasChange] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const {
    data: chargeData,
    isLoading: chargeLoading,
  } = useGetFacilityCustomChargeByIdQuery({id: chargeId},
    {refetchOnMountOrArgChange: true, skip: !chargeId}
  );

  useEffect(() => {
    if (chargeData) {
      const formattedData = {
        ...chargeData,
        mealId: [chargeData?.forBreakfast && 1, chargeData?.forLunch && 2, chargeData?.forDinner && 3].filter(Boolean)
      }

      reset(formattedData);
      setInitialValues(() => chargeData);
    }
  }, [chargeData]);

  const [
    updateCustomCharge,
    {isSubmitting: updateSaving, isSuccess: updateSuccess}
  ] = useUpdateFacilityCustomChargeMutation();

  useEffect(() => {
    if (!updateSaving && updateSuccess) {
      navigate(-1);
    }
  }, [updateSaving, updateSuccess]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = methods;

  const customCharge = watch('chargeTypeId');

  useEffect(() => {
    if(customCharge == 6) { //user wants custom input, show hidden field
      setHiddenList(() => []);
    } else {
      setHiddenList(() => [{name: "chargeTypeText"}]);
    }
  }, [customCharge]);

  const onSubmit = (data) => {
    data["facilityId"] = facilityId;

    data["forBreakfast"] = data?.mealId?.includes(1);
    data["forLunch"] = data?.mealId?.includes(2);
    data["forDinner"] = data?.mealId?.includes(3);

    setHasChange(() => false);
    updateCustomCharge(data);   
  }
  return (
    <>
      {chargeLoading ? 
        <EditSkeleton />
      :
      <>
        <BaseBlockerModal
          initialValues={initialValues}
          watch={watch}
          getValues={getValues}
          hasChange={hasChange}
          setHasChange={setHasChange}
          setInitialValues={setInitialValues}
          dirtyFields={dirtyFields}
        />
        <BaseContent
        headerText={`Edit Charge for ${facilityName}`}
        disableHeight={false}
        > 
          <Grid container spacing={2} sx={{margin: "2rem 1rem", width: "100%"}} justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap">
            <GeneratedForm
              control={control}
              list={TableSideCustomChargesFields}
              hiddenList={hiddenList}
              errors={errors}
              setValue={setValue}
            />
            <Box sx={{margin: "2rem 0 2rem 1rem"}} display="flex" alignSelf="center" alignItems="center" justifyContent="flex-start" width="100%">
              <BaseSubmitButton
                isSubmitting={updateSaving} 
                onClick={handleSubmit(onSubmit)} 
              />
            </Box>
          </Grid>
        </BaseContent>
      </>
    }
    </>
  );
}