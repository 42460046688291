import { Box, Grid, Typography } from "@mui/material";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { tableSideMealFields } from "../../form.constants";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { useUpdateTablesideSetupMutation } from "store/apis/TableSideApis";

export default function EditQueuedMeal() {
  const [searchParams] = useSearchParams();
  const setupId = searchParams.get("id");
  const navigate = useNavigate();
  const context = useOutletContext();
  const [hasChange, setHasChange] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isInitLoad, setInitLoad] = useState(true);
  const [disabledList, setDisabledList] = useState([]);

  const [queries, setQueries] = useState([
    {
      name: "menuId",
      skip: true,
    },
  ]);
    // UseForm Hooks Declaration and Destructing
    const methods = useForm({
      shouldUnregister: false,
      mode: "all",
    });

  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors, dirtyFields },
  } = methods;

  // useForm Variable Declaration
  const seasonId = watch("seasonId");
  const menuId = watch("menuId");

  // useEffect Section
  useEffect(() => {
    if (context?.tableSideData) {
      const setupData = context.tableSideData.find((setup) => setup.id == setupId);
      
      if (setupData) {
        //map from TS pending meals payload to form object for initialValue and reset
        const formValues = {
          meal: setupData.mealId,
          seasonId: setupData.seasonId,
          menuId: setupData.menuId,
          menuDay: setupData.day,
          diningDate: dayjs(setupData.diningDate).format(SCREEN_CONSTANTS.Date_Format),
          startTime: dayjs(setupData.diningDate).format(SCREEN_CONSTANTS.Time_Format)
        };

        reset(formValues);
        setInitialValues(formValues);

        if (setupData?.ordersTaken) {
          setDisabledList(() => ['meal', 'seasonId', 'menuId', 'menuDay']);
        }
      }
    }
  }, [context?.tableSideData]);

  useEffect(() => {
    //don't reset value when we load into view from queued meals
    if (isInitLoad) {
      setInitLoad(() => !initialValues?.menuId); //turns false once data from context is loaded fully
    } else {
      setValue("menuDay", 1); 
    }
    clearErrors("menuDay");
    const menuObject = context?.menuData?.find((data) => data.id == menuId);
    if (context?.menuData) {
      tableSideMealFields[3].validationProps.max.value = menuObject?.daysInMenu;
      tableSideMealFields[3].validationProps.max.message = isEqual(
        menuObject?.daysInMenu,
        1
      )
        ? "Menu Day should be 1 "
        : `Menu Day Should be between 1 to ${menuObject?.daysInMenu}`;
    }
  }, [context?.menuData, menuId]);


  useEffect(() => {
    seasonId
      ? setQueries([
          ...queries.filter((x) => x.name != "menuId"),
          {
            name: "menuId",
            query: seasonId,
            skip: !seasonId,
          },
        ])
      : null;
  }, [seasonId]);

  const [
    updateTablesideSetup,
    { isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateTablesideSetupMutation();

  useEffect(() => {
    if (updateSuccess && !updateLoading) {
      navigate(-1);
    }
  }, [updateLoading, updateSuccess]);

  const dateFormatter = (data) => {
    const parsedDate = dayjs(data.diningDate).format("YYYY-MM-DD");
    const parsedTime =
      typeof data.startTime === "string"
        ? data.startTime
        : dayjs(data.startTime).format(SCREEN_CONSTANTS.Time_Format);
    const combinedDateTime = `${parsedDate}T${parsedTime}`;
    // Parse the combined date and time using Day.js
    const parsedDateTime = dayjs(combinedDateTime);
    // Format the parsed date and time into the desired format
    const formattedDate = parsedDateTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
    return formattedDate;
  };
  
  const onSubmit = (data) => {
    setHasChange(false);

    const formattedDate = dateFormatter(data);
    const dataObject = {
      diningDate: formattedDate,
      facilityId: context?.facilityId,
      mealId: data.meal,
      day: Number.parseInt(data.menuDay),
      menuId: data.menuId,
      seasonId: data.seasonId,
      now: false,
    };

    updateTablesideSetup({id: setupId, data: dataObject});
  };

  return (
    <>
      <BaseBlockerModal
        initialValues={initialValues}
        watch={watch}
        getValues={getValues}
        hasChange={hasChange}
        setHasChange={setHasChange}
        setInitialValues={setInitialValues}
        dirtyFields={dirtyFields}
      />
      <Box sx={{ padding: "5px", marginTop: "10px" }}>
        {!!disabledList?.length && 
          <Typography color="error" variant="h6" textAlign="center">*** There are orders for this meal. Only dining date and time can be changed ***</Typography>
        }
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            margin: "1px",
          }}
        >
          <GeneratedForm
            list={tableSideMealFields}
            disabledList={disabledList}
            queries={queries}
            control={control}
            setValue={setValue}
            errors={errors}
          />

          <Grid item md={6}>
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              isSubmitting={updateLoading}
              />
          </Grid>
        </Grid>
      </Box>
    </>
  );

}