import { Box, Typography } from "@mui/material";
import { CustomNoRowsOverlay } from "components/admin/admin.overlays";
import BaseActionBox from "components/shared/BaseActionBox";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import dayjs from "dayjs";
import React from "react";
import { useOutletContext } from "react-router-dom";

export default function QueuedMeals() {

  const context = useOutletContext();
  
  const readableDate = (data) => {
    const formatedDate = dayjs(data);
    const formattedDate = formatedDate.format("YYYY-MM-DD"); // 2023-09-14
    const formattedTime = formatedDate.format("hh:mm a");
    const finalResult = formattedDate + " " + formattedTime;
    return finalResult;
  };

  const OrderColumns = [
    {
      field: "diningDate",
      minWidth: 100,
      flex: 1,
      headerName: "Dining Date",
      renderCell: (params) => {
        const finalResult = readableDate(params.row.diningDate);
        return finalResult;
      },
    },
    {
      field: "seasonName",
      // minWidth: 100,
      flex: 1,
      headerName: "Season",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "mealName",
      // minWidth: 100,
      flex: 1,
      headerName: "Meal",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },

    {
      field: "menuName",
      // minWidth: 100,
      flex: 1,
      headerName: "Menu",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "day",
      // minWidth: 100,
      flex: 1,
      headerName: "Day",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "ordersTaken",
      headerName: "Orders Taken",
      cellClassName: "cellCenterAlign",
      headerAlign: "center"
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 175,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      renderCell: (params) => {
        // const apiDate = new Date(params.row.diningDate);
        // const currentDate = new Date();
        const formatedDate = dayjs(params.row.diningDate);
        const formattedDate = formatedDate.format(SCREEN_CONSTANTS.Date_Format);
        const orderCount = params?.row?.ordersTaken ?? 0;
        const addlText = orderCount ? `*** There ${orderCount > 1 ? 'are' : 'is '} ${orderCount} ordered meal${orderCount > 1 ? "s": ""}. 
        ${orderCount > 1 ? "These" : "This"} would also be deleted. ***` 
        : "";
        return (
          <>
            {params.row?.isActive ? 
              <Typography variant="body">Active</Typography> 
              :
              <>
                <BaseActionBox
                  index={params.row.id}
                  data={context?.tableSideData}
                  hasEdit={!params.row?.isActive}
                  editProps={{
                    To: `/TableSide/KitchenSideSetup/EditQueuedMeal?id=${params.row.id}`,
                    title: `${params.row?.mealName} on ${formattedDate}`
                  }}
                  childrenLocation={"end"}>
                </BaseActionBox>
                {/* Print functionality to come in a later ticket */}
                {/* <Button
                  variant="contained"
                  color="warning"
                  title="Print"
                  icon={"true"}
                  sx={{
                    backgroundColor: "var(--tealColor)", 
                    padding: "6px", 
                    borderRadius: ".5rem", 
                    color: "#fff", 
                    "&:hover": {
                      backgroundColor: "var(--tealColor)", 
                      color: "#FFF"
                    }
                  }}
                >
                  <span className="material-icons">print</span>
                </Button> */}
                <BaseActionBox
                  index={params.row.id}
                  data={context?.tableSideData}
                  hasDelete={!params.row?.isActive}
                  deleteProps={{
                    entityName: `${params.row?.mealName} on ${formattedDate}`,
                    additionalText: addlText, 
                    apiPath: "deleteMeal",
                    FacilityId: context?.facilityId,
                  }}
                  childrenLocation={"end"}>
                </BaseActionBox>
              </>
            }
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          marginY: "10px",
        }}>
        <Typography
          variant="h6"
          component="h2"
          sx={{
            marginTop: "10px",
            fontWeight: "bold",
          }}>
          Queued Meals{" "}
        </Typography>

        <Box
          sx={{
            marginY: "10px",
          }}>
          <BaseDataGrid
            rows={context?.tableSideData}
            loading={context?.loading}
            columns={CreateBaseColumns(OrderColumns) || []}
            error={context?.hasError}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay
            }}
            // getRowHeight={() => "auto"}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                {
                  py: "22px",
                },
            }}
          />
        </Box>
      </Box>
    </>
  );
}