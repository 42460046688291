import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { useForm } from "react-hook-form";
import { useGetAvailableSeasonSelectBoxQuery } from "../../../../store/apis/SeasonApis";
import { useGetMenusForTableSideQuery } from "../../../../store/apis/MenuApis";
import { Box } from "@mui/system";
import {
  useGetFacilityTableSideAutomationQuery,
  useUpdateFacilityTableSideAutomationMutation,
} from "../../../../store/apis/FacilityApis";
import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../admin/admin.overlays";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import dayjs from "dayjs";
import { REQUIRED_ERROR } from "utils/errorMessages";
// import { SCREEN_CONSTANTS } from "components/shared/constants";
import { positiveNumber } from "utils/ValidationRegex";
import CopyIcon from "@mui/icons-material/ContentCopy";
import CopyPopover from "./CopyPopover";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";


export default function AutomaticSetup() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const popoverOpen = Boolean(popoverAnchor);
  const popoverId = popoverOpen ? 'copy-popover' : null;

  const [initialValues, setInitialValues] = useState({});
  const [hasChange, setHasChange] = useState(false);

  const handleCopyClick = (event) => {
    setPopoverAnchor(event.currentTarget);
  }

  const handlePopoverClose = (data = null) => {
    setPopoverAnchor(null);
    if (data) {
      Object.keys(data).forEach((key) => {
        if (!['selectAll', '0'].includes(key) && data[key]) {
          setValue(`breakfast-${key}`, getValues('breakfast-0'))
          setValue(`lunch-${key}`, getValues('lunch-0'));
          setValue(`dinner-${key}`, getValues('dinner-0'));
        }
      })
    }
  }

  const { data: seasons, isFetching: isFetchingSeasons } =
    useGetAvailableSeasonSelectBoxQuery({
      take: 3,
      skip: 0,
      forTableSide: true,
    });

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = methods;

  const navigate = useNavigate();

  const seasonId = watch("seasonId");

  const menuStartDate = watch("menuStartDate");

  const [
    updateAutomation,
    { isSubmitting: isfetchingAutomation, isSuccess: issuccessAutomation },
  ] = useUpdateFacilityTableSideAutomationMutation();

  const {
    data: currentData,
    isFetching: isFetchingCurrentData,
    isSuccess: isSuccessCurrentData,
  } = useGetFacilityTableSideAutomationQuery(id, { skip: !id });

  useEffect(() => {
    if (currentData && !isFetchingCurrentData && isSuccessCurrentData) {
      reset(currentData?.tableSideMenuAutomation);

      if (!currentData?.tableSideMenuAutomation?.menuStartDate) {
        setValue("menuStartDate", dayjs().format("YYYY-MM-DD"));
      }

      currentData?.tableSideMenuAutomation?.automationStartTimes?.map(
        (item) => {
          switch (item?.mealId) {
            case 1:
              setValue(
                `breakfast-${item?.dayOfTheWeek - 1}`,
                dayjs(item?.startTime).format("HH:mm")
              );
              break;
            case 2:
              setValue(
                `lunch-${item?.dayOfTheWeek - 1}`,
                dayjs(item?.startTime).format("HH:mm")
              );
              break;
            case 3:
              setValue(
                `dinner-${item?.dayOfTheWeek - 1}`,
                dayjs(item?.startTime).format("HH:mm")
              );
              break;
          }
        }
      );

      setInitialValues(getValues());
    }
  }, [currentData, isFetchingCurrentData, isSuccessCurrentData]);

  useEffect(() => {
    if (!isfetchingAutomation && issuccessAutomation) {
      navigate(-1);
    }
  }, [issuccessAutomation, isfetchingAutomation]);

  const onSubmit = (data) => {
    data["facilityId"] = id;

    data["saveData"] = "";

    for (let i = 0; i < 7; i++) {
      // dayjs(`2022-04-17T${value}`)

      var breakfast = data["breakfast-" + i];
      var lunch = data["lunch-" + i];
      var dinner = data["dinner-" + i];

      if (!dayjs(breakfast).isValid()) {
        breakfast = dayjs(`2022-04-17T${breakfast}`);
      }

      if (!dayjs(lunch).isValid()) {
        lunch = dayjs(`2022-04-17T${lunch}`);
      }

      if (!dayjs(dinner).isValid()) {
        dinner = dayjs(`2022-04-17T${dinner}`);
      }

      data.saveData += `${i + 1}_1=${dayjs(breakfast).format("HH:mm")};`;
      data.saveData += `${i + 1}_2=${dayjs(lunch).format("HH:mm")};`;
      data.saveData += `${i + 1}_3=${dayjs(dinner).format("HH:mm")};`;
    }

    // var myreturn = true;

    // if (myreturn) {
    //   return;
    // }

    setHasChange(false);
    
    updateAutomation({
      ...data,
      dateEndTemporary: dayjs(data?.dateEndTemporary).isValid()
        ? dayjs(data?.dateEndTemporary).format("YYYY-MM-DD")
        : null,
    });
  };

  const { data: menus, isFetching: isFetchingMenus } =
    useGetMenusForTableSideQuery(
      {
        seasonId: seasonId,
        facilityId: id,
      },
      { skip: !id || !seasonId }
    );

  const isEditLoading =
    isFetchingCurrentData || isFetchingSeasons || isFetchingMenus;

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <> 
          <BaseBlockerModal
            initialValues={initialValues}
            watch={watch}
            getValues={getValues}
            hasChange={hasChange}
            setHasChange={setHasChange}
            setInitialValues={setInitialValues}
            dirtyFields={dirtyFields}
          />

        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "4px",
          }}>
          <Grid
            item
            md={12}
            sx={{
              marginTop: "-5px",
            }}>
            <Typography
              variant="p"
              component={"p"}
              align="left"
              sx={{
                fontWeight: "600",
              }}>
              {`Timezone setup is ${currentData?.tableSideMenuAutomation?.timeZoneName}`}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <BaseDatePicker
              name="menuStartDate"
              label="Menu Start Date"
              id="menuStartDate"
              defaultValue={dayjs().format("YYYY-MM-DD")}
              control={control}
              errors={errors}
              validationProps={{ required: REQUIRED_ERROR("Menu Start Date") }}
            />
          </Grid>
          <Grid item md={3}>
            <BaseInput
              name="menuStartDay"
              label="Menu Start Day"
              id="menuStartDay"
              type="number"
              defaultValue={0}
              control={control}
              errors={errors}
              validationProps={{
                required: REQUIRED_ERROR("Menu Start Day"),
                maxLength: {
                  value: 3,
                  message: "Please enter 3 numbers or less",
                },
                pattern: {
                  value: positiveNumber,
                  message: "Please enter positive number",
                },
              }}
            />
          </Grid>
          <Grid item md={3}>
            <BaseInput
              name="createDaysAhead"
              label="Days Ahead to Create"
              id="createDaysAhead"
              type="number"
              control={control}
              min={1}
              defaultValue={1}
              errors={errors}
              validationProps={{
                required: REQUIRED_ERROR("Days Ahead to Create"),
                maxLength: {
                  value: 3,
                  message: "Please enter 3 numbers or less",
                },
                pattern: {
                  value: positiveNumber,
                  message: "Please enter positive number",
                },
              }}
            />
          </Grid>
          <Grid item md={6}>
            <BaseSelect
              name="seasonId"
              id="seasonId"
              label="Season"
              control={control}
              options={seasons || []}
              loading={isFetchingSeasons}
              validationProps={{
                required: REQUIRED_ERROR("Season"),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item md={6}>
            <BaseSelect
              name="menuId"
              id="menuId"
              label="Menu"
              control={control}
              options={menus || []}
              loading={isFetchingMenus}
              validationProps={{
                required: REQUIRED_ERROR("Menu"),
              }}
              errors={errors}
            />
          </Grid>
          {Array.from({ length: 7 }).map((it, index) => (
            <Grid item md={3} key={index}>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: 1,
                  borderColor: "black",
                  p: 2,
                }}>
                <Box width="100%" display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center">
                  <Typography
                    align="center"
                    sx={{
                      fontWeight: "700",
                      top: "50%",
                      left: "50%",
                      position: index === 0 ? "relative" : "static",
                      transform: index === 0 ? "translate(-50%)" : ""
                    }}
                  >
                    {dayjs(menuStartDate).add(index, "days").format("dddd")}
                  </Typography>
                  {index === 0 && 
                    <>
                      <IconButton
                        aria-describedby={popoverId}
                        variant="contained"
                        sx={{
                          display: "inline-flex",
                          backgroundColor: "var(--orangeColor)",
                          ":hover": {backgroundColor: "#B28920"}, 
                          " > *": {fontSize: ".875rem"},
                          borderRadius: ".25rem !important",
                          padding: ".25rem",
                          marginLeft: "auto"
                        }}
                        onClick={handleCopyClick}
                      >
                        <CopyIcon />
                      </IconButton>
                      <CopyPopover
                        id={popoverId}
                        open={popoverOpen}
                        popoverAnchor={popoverAnchor}
                        handleClose={handlePopoverClose}
                        menuStartDate={menuStartDate}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right"
                        }}
                      />
                    </>
                  }
                </Box>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginTop: "2px",
                  }}>
                  <Grid item md={12}>
                    <BaseDatePicker
                      name={"breakfast-" + index}
                      label="Breakfast"
                      id={"breakfast-" + index}
                      defaultValue={"06:30"}
                      type="time"
                      timeFormat="HH:mm"
                      control={control}
                      errors={errors}
                      validationProps={{
                        required: REQUIRED_ERROR("Breakfast Time"),
                      }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <BaseDatePicker
                      name={"lunch-" + index}
                      label="Lunch"
                      id={"lunch-" + index}
                      type="time"
                      timeFormat="HH:mm"
                      defaultValue={"11:00"}
                      control={control}
                      errors={errors}
                      validationProps={{
                        required: REQUIRED_ERROR("Lunch Time"),
                      }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <BaseDatePicker
                      name={"dinner-" + index}
                      label="Dinner"
                      id={"dinner-" + index}
                      type="time"
                      timeFormat="HH:mm"
                      defaultValue={"16:30"}
                      control={control}
                      errors={errors}
                      validationProps={{
                        required: REQUIRED_ERROR("Dinner Time"),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
          <Grid item md={12}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <BaseSubmitButton onClick={handleSubmit(onSubmit)} />
            </Box>
          </Grid>
        </Grid>
        </>
      )}
    </>
  );
}
