import { Box, Grid } from "@mui/material";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React, { useEffect, useState } from "react";
import { TableSideCustomChargesFields } from "./form.constants";
import { useForm } from "react-hook-form";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import { CustomNoRowsOverlay } from "components/admin/admin.overlays";
import { useGetFacilityCustomChargesQuery, usePostFacilityCustomChargeMutation } from "store/apis/FacilityApis";
import { useSelector } from "react-redux";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import BaseActionBox from "components/shared/BaseActionBox";

export default function CustomCharges() {

  const facilityId = useSelector(selectFacilityId);
  const [hiddenList, setHiddenList] = useState([]);

  const ChargesColumns = [
    {
      field: "chargeTypeId",
      minWidth: 100,
      flex: 1,
      headerName: "Charge",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.chargeTypeId === 6 ? params?.row?.chargeTypeText : params?.row?.chargeTypeName;
      }
    },
    {
      field: "meal",
      minWidth: 100,
      flex: 1,
      headerName: "Meal",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        let mealName = "";
        const mealList = [];
        if (params?.row?.forBreakfast && params?.row?.forLunch && params?.row?.forDinner) {
          mealName = "All Meals";
        } else { //build meal list
          if (params?.row?.forBreakfast) {
            mealList.push("Breakfast");
          } 
          if (params?.row?.forLunch) {
            mealList.push("Lunch");
          } 
          if (params?.row?.forDinner) {
            mealList.push("Dinner");
          }

          mealName = mealList.join(", ");
        }  

        return mealName;
      }
    },
    {
      field: "price",
      minWidth: 100,
      flex: 1,
      headerName: "Price",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueFormatter: (value) => {
        return `$${value ? value.toFixed(2) : Number(0).toFixed(2)}`
      }
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 175,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={chargeData}
            hasEdit={true}
            editProps={{
              To: `/TableSide/Charges/EditCharge?id=${params.row.id}`,
              title: `${params.row?.chargeTypeId === 6 ? params.row.chargeTypeText : params.row?.chargeTypeName}`
            }}
            hasDelete={true}
            deleteProps={{
              entityName: `${params?.row?.chargeTypeName} charge`,
              apiPath: "deleteFacilityCustomCharge",
              FacilityId: facilityId,
            }}
            childrenLocation={"end"}>
          </BaseActionBox>
        
        );
      },
    }
  ];

  const {
    data: chargeData,
    isLoading: chargeLoading,
    isError: chargeError,
    refetch: refetchCustomCharges
  } = useGetFacilityCustomChargesQuery();

  const [
    addCustomCharge,
    {isSubmitting: addSaving, isSuccess: addSuccess}
  ] = usePostFacilityCustomChargeMutation();

  useEffect(() => {
    if (!addSaving && addSuccess) {
      refetchCustomCharges();
      reset();
    }
  }, [addSaving, addSuccess]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const customCharge = watch('chargeTypeId');

  useEffect(() => {
    if(customCharge == 6) { //user wants custom input, show hidden field
      setHiddenList(() => []);
    } else {
      setHiddenList(() => [{name: "chargeTypeText"}]);
    }
  }, [customCharge]);

  const onSubmit = (data) => {

    data["facilityId"] = facilityId;
    
    data["forBreakfast"] = data?.mealId?.includes(1);
    data["forLunch"] = data?.mealId?.includes(2);
    data["forDinner"] = data?.mealId?.includes(3);
    
    addCustomCharge(data);   
  }

  return (
    <>
      <Grid container sx={{margin: "2rem 10%", width: "80%"}} justifyContent="space-around" alignItems="flex-start">
        <GeneratedForm
          control={control}
          list={TableSideCustomChargesFields}
          hiddenList={hiddenList}
          errors={errors}
          setValue={setValue}
        />
        <Box display="flex" alignSelf="center" alignItems="center" justifyContent="flex-end">
          <BaseSubmitButton
            isSubmitting={addSaving} 
            text={"Add"}
            onClick={handleSubmit(onSubmit)} 
          />
        </Box>
      </Grid>
      <Box sx={{width: "70%", margin: "3rem 15% 2rem", height: "350px"}}>
        <BaseDataGrid 
          rows={chargeData}
          loading={chargeLoading}
          columns={CreateBaseColumns(ChargesColumns) || []}
          error={chargeError}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay
          }}
          // getRowHeight={() => "auto"}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
              {
                py: "22px",
              },
          }}
        />
      </Box>
    </>
  )
}