/* eslint-disable no-undef */
import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_B2CPOLICIES_SIGNUP_SIGNIN,
    forgotPassword: process.env.REACT_APP_B2CPOLICIES_forgotPassword,
    logonAs: process.env.REACT_APP_B2CPOLICIES_LogonAs,
  },
  authorities: {
    signUpSignIn: {
      authority:
        process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY,
    },
    forgotPassword: {
      authority:
      process.env.REACT_APP_forgotPassword_AUTHORITY,
    },
    logonAs: {
      authority:
      process.env.REACT_APP_LogonAs_AUTHORITY,
    },
  },
  authorityDomain: "diningmanagerb2c.b2clogin.com",
  // eslint-disable-next-line no-undef
  extraQueryParameters: { server: process.env.REACT_APP_BASE },
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
//    clientId: "bc4d46d7-4f6e-4f3c-be34-78a6c0fb7e16",
    clientId: process.env.REACT_APP_CLIENTID,  //"325f03c1-aa5c-4bd1-a3d5-c23303a31fef",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    postLogoutRedirectUri: "/home",
    redirectUri: "/",
    // navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage", //"sessionStorage", // "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [
    process.env.REACT_APP_SCOPES_READ,
    process.env.REACT_APP_SCOPES_WRITE,
  ],
  // eslint-disable-next-line no-undef
  extraQueryParameters: { server: process.env.REACT_APP_BASE }, //`${process.env.REACT_APP_BASE}`
};
export const loginRequestSubs = {
  scopes: [
    process.env.REACT_APP_SCOPES_POC_READ,
    process.env.REACT_APP_SCOPES_POC_WRITE,
  ],
  // eslint-disable-next-line no-undef
  extraQueryParameters: { server: process.env.REACT_APP_BASE }, //`${process.env.REACT_APP_BASE}`
};
//AI
export const AIRequest = {
  scopes: [
    process.env.REACT_APP_SCOPES_AI_READ,
    process.env.REACT_APP_SCOPES_AI_WRITE,
  ],
  // eslint-disable-next-line no-undef
  extraQueryParameters: { server: process.env.REACT_APP_BASE }, //`${process.env.REACT_APP_BASE}`
};
