import React from "react";

import ProtectedRoute from "../ProtectedRoute";
import { PAGE_ROLES } from "components/shared/constants";

// TableSide Modules Imports
import { TableSide } from "components/tableSide/TableSide";
import { StartMeal } from "components/tableSide/startMeal/StartMeal";
import OrderTracking from "components/tableSide/orderTracking/OrderTracking";
import { Reports } from "components/tableSide/report/Reports";
import TicketPrinting from "components/tableSide/ticketPrinting/TicketPrinting";
import AutomaticSetup from "components/tableSide/startMeal/automaticSetup/AutomaticSetup";
import QueuedMeals from "components/tableSide/startMeal/queuedMeals/QueuedMeals";
import EditQueuedMeal from "components/tableSide/startMeal/queuedMeals/editQueuedMeal/EditQueuedMeal";
import Charges from "components/tableSide/charges/Charges";
import EditCharge from "components/tableSide/charges/editCharge/EditCharge";

const { TABLESIDE } = PAGE_ROLES;

export const TableSideMainRoute = {
  path: "TableSide",
  element: (
    <ProtectedRoute allowedRoles={TABLESIDE}>
      <TableSide />
    </ProtectedRoute>
  ),
};

const KitchenSideSetup = {
  path: "TableSide/KitchenSideSetup",
  element: (
    <ProtectedRoute allowedRoles={TABLESIDE}>
      <StartMeal />
    </ProtectedRoute>
  ),
  children: [
    { path: "QueuedMeals", element: <QueuedMeals /> },
    { path: "AutomaticSetup", element: <AutomaticSetup /> },
    { path: "EditQueuedMeal", element: <EditQueuedMeal /> }
  ]
};

const orderTracking = {
  path: "TableSide/orderTracking",
  element: (
    <ProtectedRoute allowedRoles={TABLESIDE}>
      <OrderTracking />
    </ProtectedRoute>
  ),
};

const ReportRoute = {
  path: "TableSide/Reports",
  element: (
    <ProtectedRoute allowedRoles={TABLESIDE}>
      <Reports />
    </ProtectedRoute>
  ),
};

const TicketPrintingRoute = {
  path: "TableSide/TicketPrinting",
  element: (
    <ProtectedRoute allowedRoles={TABLESIDE}>
      <TicketPrinting />
    </ProtectedRoute>
  ),
};

const ChargesRoute = {
  path: "TableSide/Charges",
  element: (
    <ProtectedRoute allowedRoles={TABLESIDE}>
      <Charges />
    </ProtectedRoute>
  )
}

const EditChargeRoute = {
  path: "TableSide/Charges/EditCharge",
  element: (
    <ProtectedRoute allowedRoles={TABLESIDE}>
      <EditCharge />
    </ProtectedRoute>
  )
}

export const TableSideRoutes = {
  TableSideMainRoute,
  KitchenSideSetup,
  TicketPrintingRoute,
  ReportRoute,
  orderTracking,
  ChargesRoute,
  EditChargeRoute
};
