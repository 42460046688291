import { Box, Button, Grid, styled, Tooltip, tooltipClasses } from "@mui/material";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "components/shared/baseSearch/BaseSearch";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TableSideItemizedPricesFields } from "./form.constants";
import { CustomNoRowsOverlay } from "components/admin/admin.overlays";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import BaseActionBox from "components/shared/BaseActionBox";
import { useGetFacilityMenuPriceListQuery, usePostFacilityMenuPriceMutation } from "store/apis/FacilityApis";
import { GridCellEditStopReasons, GridEditInputCell, GridRowEditStopReasons, GridRowModes } from "@mui/x-data-grid";
import { positiveDollarNumber } from "utils/ValidationRegex";
import CopyPriceModal from "./copyPriceModal/CopyPriceModal";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function PriceEditInputCell(props) {
  const { error, errormessage} = props;

  return (
    <StyledTooltip open={!!error} title={errormessage}>
      <Box>
        <GridEditInputCell {...props} onWheel={(event) =>  event?.target?.blur()}/>
      </Box>
    </StyledTooltip>
  );
}

function renderEditPrice(params) {
  return <PriceEditInputCell {...params} />;
}

export default function ItemizedPrices() {

  const preProcessEditCellProps = async (params) => {
    const regexCheck = positiveDollarNumber.exec(params.props.value);
    const valueCheck = Number.parseFloat(params?.props?.value?.toFixed(2)) <= 50;
    let errorMessage = ""
    if (!regexCheck?.length) {
      errorMessage = 'Invalid Price';
    } else if (!valueCheck) {
      errorMessage = 'Price must be $50 or less';
    }
    
    params.row.disableSave = !!errorMessage;
    return { ...params.props, error: !!errorMessage, errormessage: errorMessage};
  }

  const [searchValue, setSearchValue] = useState("");
  const [editableItemPrices, setEditableItemPrices] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [copyItem, setCopyItem] = useState(null);
  const [categoryPriceList, setCategoryPriceList] = useState([]);
  const [debouncedValue, setDebouncedValue] = useState("");

  const [queries, setQueries] = useState([
    {
      name: "menuId",
      skip: true,
    },
  ]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const seasonId = watch("seasonId");
  const menuId = watch("menuId");


  
  const {
    data: itemPrices,
    isFetching: pricesLoading,
    isError: pricesError
  } = useGetFacilityMenuPriceListQuery(menuId, 
    {refetchOnMountOrArgChange: true, skip: !menuId}
  );

  useEffect(() => {
    if (itemPrices) {
      if (debouncedValue?.length === 0) {
        setEditableItemPrices(() => itemPrices.map((item) => {
          return {
            ...item,
            disableSave: false
          }
        }));
      } else {
        const filteredItems = itemPrices?.filter((item) => item.recipeName?.toLowerCase()?.includes(debouncedValue?.toLowerCase()));
        setEditableItemPrices(() => filteredItems?.map((item) => {
          return {
            ...item,
            disableSave: false
          }
        }));
      }
    }
  }, [debouncedValue, itemPrices]);

  const [
    addItemPrice
  ] = usePostFacilityMenuPriceMutation();

  useEffect(() => {
    if (itemPrices) {
      setEditableItemPrices(() => itemPrices.map((item) => {
        return {
          ...item,
          disableSave: false
        }
      })); 
    }
  }, [itemPrices])

  useEffect(() => {
    seasonId
      ? setQueries([
          ...queries.filter((x) => x.name != "menuId"),
          {
            name: "menuId",
            query: seasonId,
            skip: !seasonId,
          },
        ])
      : null;
  }, [seasonId]);

  const handleRowEditStop = (params, event) => {
    if (params?.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }

  const handleCellEditStop = (params, event) => {
    if (params?.reason === GridCellEditStopReasons.cellFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit }});
  }

  const handleSaveClick = (id, row) => () => {
    if (!row.disableSave) {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View }});
    }

  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true }})
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = (newRow) => {
    const data = {
      menuId: menuId,
      recipes: [newRow] //API can take in list of recipes for bulk price application, just wrap in array on row edit
    }

    addItemPrice(data);

    const updatedRow = { ...newRow, isNew: false };
    setEditableItemPrices(editableItemPrices.map((row) => (row.id === newRow.id ? updatedRow : row)));

    return updatedRow;
  };

  const handleCopyOpenClick = (item) => {
    setCopyModalOpen(true);
    setCopyItem(item);
    setCategoryPriceList(editableItemPrices.filter((i) => i?.mealCategory == item.mealCategory && i?.id !== item?.id));
  }

  const handleCopyModalClose = () => {
    setCopyModalOpen(false);
  }

  const PricesColumns = [
    {
      field: "recipeName",
      minWidth: 200,
      width: 250,
      flex: 1,
      headerName: "Menu Item",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "mealCategory",
      minWidth: 200,
      flex: 0,
      headerName: "Category",
      cellClassName: "cellCenterAlign",
      headerAlign: "center"
    },
    {
      field: "mealName",
      minWidth: 100,
      width: 150,
      flex: 0,
      headerName: "Meal",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "mealPrice",
      minWidth: 100,
      width: 125,
      flex: 0,
      headerName: "Price",
      type: "number",
      editable: true,
      preProcessEditCellProps,
      renderEditCell: renderEditPrice,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueFormatter: (value) => {
        return `$${value ? value.toFixed(2) : Number(0).toFixed(2)}`;
      }
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 0,
      disableClickEventBubbling: true,
      headerAlign: "center",
      cellClassName: "cellLeftAlign",
      getActions: ({ id, row }) => {
        const isEditing = rowModesModel?.[id]?.mode === GridRowModes.Edit;

        if (isEditing) {
          return [
            <Button
              key={id}
              variant="contained"
              color="primary"
              title={"Save Row"}
              icon={"true"}
              dark={"true"}
              onClick={handleSaveClick(id, row)}
            >
              <span className="material-icons" style={{color: "#FFF"}}>save</span>
            </Button>,
            <Button
              key={id}
              variant="contained"
              color="error"
              title="Cancel"
              icon={"true"}
              dark={"true"}
              onClick={handleCancelClick(id)}
            >
              <span className="material-icons" style={{color: "#FFF"}}>cancel</span>
            </Button>
          ];
        } else {
          return [
            <BaseActionBox
              key={id}
              index={id}
              data={itemPrices}
              hasEdit={true}
              onClick={handleEditClick(id)}
              childrenLocation={"end"}>
            </BaseActionBox>,
            <Button
              key={id}
              sx={{display: row?.mealPrice ? "inline-grid" : "none"}}
              variant="contained"
              color="#FFF"
              title="Copy Price"
              icon={"true"}
              dark={"true"}
              onClick={() => handleCopyOpenClick(row)}
            >
              <span className="material-icons" style={{color: "var(--yellowColor)"}}>content_copy</span>
            </Button>
          ];
        }
      },
    }
  ]

  return (
    <>
      <Grid container spacing={2} sx={{margin: "1rem 15%", width: "70%"}} justifyContent="space-between" alignItems="flex-start">
        <GeneratedForm
          list={TableSideItemizedPricesFields}
          queries={queries}
          control={control}
          setValue={setValue}
          errors={errors}
        />
      </Grid>
      <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
        />
      </Box>
      <Box 
        sx={{
          width: "70%", 
          margin: "2rem 15%", 
          height: "550px", 
          "& .MuiInputBase-root": {
              "& input[type=number]": {
                textAlign: "center",
              },
            },
            "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
              backgroundColor: "rgb(0, 188, 190, .4) !important",
            }
        }}
      >
        <BaseDataGrid 
          rows={editableItemPrices}
          loading={pricesLoading}
          columns={CreateBaseColumns(PricesColumns) || []}
          error={pricesError}
          rowBufferPx={920}
          getRowClassName={(params) => params?.row?.mealPrice ? "" : "no-item-price-row"}
          editMode="row"
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          onCellEditStop={handleCellEditStop}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay
          }}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
              {
                py: "22px",
              },
          }}
        />
      </Box>
      {copyModalOpen &&
        <CopyPriceModal 
          open={copyModalOpen}
          handleClose={handleCopyModalClose}
          copyItem={copyItem}
          menuId={menuId}
          priceList={categoryPriceList}
        />
      }
    </>
  )
}