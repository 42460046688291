/**
 * This is a regular expression that matches a US phone number.
 * It matches 555-555-5555, (555) 555-5555, 555 555 5555, 5555555555, etc.
 */
export const usPhoneNumberValidation = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

// Regex to allow spaces and special characters except !@#$%^&*(),.?":{}|<>_+=\/-[]`~';
// and should not start with special characters or spaces
// and should not end with spaces
// and should not be empty
export const baseNameValidation =
  /^(?!\s*$)(?![!@#$%^&*(),.?":{}|<>_+=\\/\-[\\\]`~;]*$)[A-Za-z\d][A-Za-z\d\s\-_.,()']*$/;

// Regex to allow spaces and special characters
// and should not start with special characters or spaces
// and should not end with spaces
// and should not be empty
export const baseNameValidationWithSpecialCharacters =
  /^(?!\s*$)[A-Za-z][A-Za-z\d\s\-_.,()'!@#$%^&*(),.":{}|<>_+=\\/\-[\\\]`~]*$/;

// Regex to allow spaces and special characters and numbers
// and should not start with special characters or spaces
// and should not end with spaces
// and should not be empty
export const baseNameValidationWithSpecialCharactersAndNumbers =
  /^(?!\s*$)[-A-Za-z0-9*][A-Za-z0-9\u00C0-\u017F\d\s\-;_.,()'!@#$%^&*(),.":{}|<>_+=\\/\-[\\\]`~]*$/;

// Regex to allow spaces and special characters and numbers
// and can start with *, (, #, $, %, &, -, +, or spaces
// and should not end with spaces
// and should not be empty
export const baseNameValidationWithEHRLiquidSpecialCharactersAndNumbers =
  /^(?!\s*$)[-A-Za-z0-9*(#$%&\-+\s][A-Za-z0-9\u00C0-\u017F\d\s\-;_.,()'!@#$%^&*(),.":{}|<>_+=\\/\-[\\\]`~]*$/;

// Regex to allow only alphabets for names /^[A-Za-z]+$/;
// and should not start with special characters or spaces
// and should not end with spaces
// and should not be empty
export const baseNameAlphabetsValidation = /^[A-Za-z]+$/;

// Regex to allow only alphanumeric for names /^[A-Za-z]+$/;
// and should not start with special characters or spaces
// and should not end with spaces
// and should not be empty
export const baseNameAlphaNumericValidation = /^[A-Za-z0-9 ]+$/;

export const baseNameAlphaNumericAltValidation = /^[A-Za-z0-9-.,'() *]+$/;

export const baseNameAlphaNumericSpcialCharacterAltValidation =
  /^[A-Za-z0-9-.,' * ! #()]+$/;
// Regex to allow spaces and special characters except !@#$%^&*,.?":{}|<>_+=\/-[]`~';
// and should not start with special characters or spaces, but can start and end with numbers
// and should not end with spaces
// and should not be empty
export const foodTypeNameValidation =
  /^(?!\s*$)(?![!@#$%^&*(),.?":{}|<>_+=\\/\-[\\\]`~';]*$)[A-Za-z0-9][A-Za-z\d\s\-/%&()_.,']*$/;

/**
 * Email validation regular expression.
 * This regular expression is used to validate email addresses.
 * It is based on the HTML5 specification for email validation.
 * It will allow any email address that is valid according to the HTML5 specification.
 */
export const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

/**
 * Email validation regular expression.
 * This regular expression is used to validate email addresses.
 * It is based on the HTML5 specification for email validation.
 * It will allow any email address that is valid according to the HTML5 specification.
 */
export const multipleEmailValidation =
  /^\s*([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})(\s*,\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})*\s*$/i;

/**
 * Room number validation regular expression.
 * This regular expression is used to validate room numbers.
 * It allows for a combination of the following characters:
 * - Lowercase letters (a-z)
 * - Uppercase letters (A-Z)
 * - Digits (1-9)
 * - Hyphen (-)
 * The expression ensures that the provided string adheres to the specified pattern.
 * The entire string must match for a successful validation.
 */
export const roomNumberValidation = /^[a-zA-Z0-9-]+$/;

export const positiveNumber = /^\d*\.?\d+$/;

/**
 * positiveDecimalInteger, it will allow any positive integer with decimal points.
 */
export const positiveDecimalInteger = /^\d*\.?\d+$/;

export const positiveDollarNumber = /^\d+\.?\d{0,2}$/;

/**
 * positiveIntegerNumber, it will allow any positive integer without decimal points.
 */
export const positiveIntegerNumber = /^\d*\d+$/;

export const oneOrTwoDigitPositiveNumber = /^(?:[1-9]|[1-9]\d)$/;

export const oneToThreeDigitPositiveNumber = /^(?:\d{1,3})?$/;

export const zeroToFiveDigitPositiveNumber = /^(?:\d{0,5})?$/;

export const zipCodeValidation =
  /^(?:\d{5}(?:[-]?\d{4})?|[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d)$/i;

export const passwordValidation =
  /^(?:(?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*[\W_])|(?=.*\d)(?=.*[\W_])).+$/;

export const domesticUSContactValidation = /^\d{3}-?\d{3}-?\d{4}$/;
// export const numberRegex = /^-?\d+(\.\d+)?$/;
export const numberRegex = /^-?\d+(\.\d+)?$|^-?\d+\.$/;
export const numberWithHyphen = /^\d{3}-\d{3}-\d{4}$/;

// html string non empty check
export const isHtmlEmpty = /^(?!<p>\s*(<br\s*\/?>)?\s*<\/p>\s*$).+/;
