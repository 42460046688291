import React, { useEffect, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { CustomNoRowsOverlay } from "components/admin/admin.overlays";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import CloseIcon from "@mui/icons-material/Close";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import { usePostFacilityMenuPriceMutation } from "store/apis/FacilityApis";
import BaseSearch from "components/shared/baseSearch/BaseSearch";


// Modal Styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

export default function CopyPriceModal({ open, handleClose, copyItem, menuId, priceList }) {
  const [items, setItems] = useState([]);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [priceListSource, setPriceListSource] = useState([]);

  const [debouncedValue, setDebouncedValue] = useState("");
  

  useEffect(() => {
    if (priceList?.length) {
      setPriceListSource(() => [...priceList]);
      setItems(() => [...priceList]);
    }
  }, [priceList]);

  useEffect(() => {
    if (priceListSource?.length) {
      if (debouncedValue?.length === 0) {
        setItems(() => [...priceListSource]);
      } else {
        const filteredItems = priceListSource?.filter((item) => item.recipeName?.toLowerCase()?.includes(debouncedValue?.toLowerCase()));
        setItems(() => [...filteredItems]);
      }
    }
  }, [debouncedValue, priceListSource]);

  const [
    addItemPrice,
    {
      isSubmitting: priceSubmitting,
      isSuccess: priceSuccess
    }
  ] = usePostFacilityMenuPriceMutation();

  useEffect(() => {
    if (!priceSubmitting && priceSuccess) {
      handleClose();
    }
  }, [priceSubmitting, priceSuccess]);

  const handleCopy = () => {
    const selectedRecipes = priceList.filter((item) => selectedItemIds.includes(item?.id))
      .map((item) => {
      return {
        ...item,
        mealPrice: copyItem.mealPrice
      }
    });
    const data = {
      menuId: menuId,
      recipes: [...selectedRecipes]
    }

    addItemPrice(data);
  }

  const PricesColumns = [
    {
      field: "recipeName",
      minWidth: 200,
      width: 250,
      flex: 1,
      headerName: "Menu Item",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "mealCategory",
      minWidth: 200,
      flex: 0,
      headerName: "Category",
      cellClassName: "cellCenterAlign",
      headerAlign: "center"
    },
    {
      field: "mealName",
      minWidth: 100,
      width: 150,
      flex: 0,
      headerName: "Meal",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "mealPrice",
      minWidth: 100,
      width: 125,
      flex: 0,
      headerName: "Current Price",
      type: "number",
      editable: true,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueFormatter: (value) => {
        return `$${value ? value.toFixed(2) : Number(0).toFixed(2)}`;
      }
    }
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Copy-Price-Modal"
      aria-describedby="Copy-Price-Modal"
    >
      <Box sx={style}>
        <Box>
          <CloseIcon
            sx={{
              float: "right",
              border: "1px solid",
              borderRadius: "50%",
              borderColor: "primary.main",
              padding: "5px",
              margin: "5px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </Box>
        <Typography
          variant="h6"
          component="h6"
          sx={{
            fontWeight: "bold",
            marginBottom: "10px",
          }}>
          Copy Price {`$${copyItem?.mealPrice?.toFixed(2)} from ${copyItem?.recipeName || ""} to:`}
        </Typography>
        <Box sx={{width: "100%", display: "flex", marginBottom: "1rem", justifyContent: "flex-start"}}>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
          />
        </Box>
        <BaseDataGrid 
          rows={items}
          columns={CreateBaseColumns(PricesColumns) || []}
          rowBufferPx={920}
          checkboxSelection
          rowSelectionModel={selectedItemIds}
          onRowSelectionModelChange={(newModel) => {
            setSelectedItemIds(newModel);
          }}
          autoHeight={false}
          indeterminateCheckboxAction="select"
          width={"50vw"}
          height={{ md: "48vh", lg: "52vh", xl: "54vh" }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay
          }}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
              {
                py: "22px",
              },
          }}
        />
        <Box sx={{alignItems: "flex-end", margin: ".5rem"}}>
          <BaseSubmitButton
            text="Apply"
            onClick={handleCopy}
            disabled={selectedItemIds.length == 0}
            isSubmitting={priceSubmitting}
          />
        </Box>
      </Box>
    </Modal>
  );
}