import { Typography } from "@mui/material";
import { isUndefined } from "lodash";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export default function SubRecipeDragWrapper({ props, index, moveSubRecipe }) {

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "grid",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    canDrop(item) {
      return item.canDrop;
    },
    hover(item, monitor) {
      
      if (!ref?.current) {
        return;
      }
      
      const dragIndex = item?.index;
      let hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = hoverRect?.height / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset?.y - hoverRect?.top;

      if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
          (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) {
        return; 
      }

      if (dragIndex == hoverIndex && hoverClientY > hoverMiddleY) {
        hoverIndex++;
      }

      if (!isUndefined(dragIndex) && !isUndefined(index)) {
        moveSubRecipe(dragIndex, hoverIndex, item);
      }

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, dragRef] = useDrag({
    type: "grid", 
    item: props.row,
    collect: (monitor) =>({
      isDragging: monitor.isDragging()
    })
  }, [props]);

  dragRef(drop(ref));

  return (
    <div
      className="subRecipeWrapper"
      ref={ref}
      data-handler-id={handlerId}
      style={{
      display: "flex",
      height: "100%", 
      width: "100%",   
      flexWrap: "nowrap", 
      alignItems: "center", 
      justifyContent: "flex-start",
      opacity: isDragging ? 0.5 : 1,
    }}
    >
      <span className="material-icons" style={{cursor: "grab"}}>reorder</span>
      <Typography sx={{marginLeft: "1rem"}} variant="body1" title={props?.row?.subRecipe || ""}>{props?.row?.subRecipe || ""}</Typography>
    </div>
  );
}