import { Card, CardContent, Typography } from "@mui/material";
import BaseContent from "components/shared/baseContent/BaseContent";
import React from "react";
import { useSelector } from "react-redux";
import MealPrices from "./MealPrices";
import CustomCharges from "./CustomCharges";
import ItemizedPrices from "./ItemizedPrices";

export default function Charges() {

  const facilityName = useSelector((state) => state.userInfo.facilityName);

  return (
    <>
      <BaseContent
        headerText={`TableSide Charges for ${facilityName}`}
        disableHeight={true}
      >
        <Card sx={{width: "90%", margin: "1rem 5%"}}>
          <CardContent sx={{padding: ".5rem !important", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "var(--tealColor)"}}>
            <Typography 
              variant="h5"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#FFF"
              }}>
              Meal Prices
            </Typography>
          </CardContent>
        </Card>

        <MealPrices/>

        <Card sx={{width: "90%", margin: "1rem 5%"}}>
          <CardContent sx={{padding: ".5rem !important", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "var(--tealColor)"}}>
            <Typography 
              variant="h5"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#FFF"
              }}>
              Add Custom Charge
            </Typography>
          </CardContent>
        </Card>

        <CustomCharges />

        <Card sx={{width: "90%", margin: "1rem 5%"}}>
          <CardContent sx={{padding: ".5rem !important", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "var(--tealColor)"}}>
            <Typography 
              variant="h5"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#FFF"
              }}>
              Itemized Menu Prices
            </Typography>
          </CardContent>
        </Card>
          
          <ItemizedPrices />

      </BaseContent>
    </>
  );
}